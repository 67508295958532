import pinia from '@/stores/store.js';
import Router from 'vue-router';
import { useLoginStore } from '@/stores/loginStore';

import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'
import PCMIReportsTranslation from "src/pages/Dashboard/Pages/Reports/PCMIReportsTranslation.vue";
import DNAReport from "src/pages/Dashboard/Pages/Reports/DNAReport.vue";
import QuestReport from "src/pages/Dashboard/Pages/Reports/QuestReport.vue";
import NOVAReport from "src/pages/Dashboard/Pages/Reports/NOVAReport.vue";
import CoverageCodes from "src/pages/Dashboard/Pages/Reports/CoverageCodes.vue";
import DealerAndAgentWizard from "src/pages/Dashboard/Pages/DealerAndAgentWizard.vue";
import RollingReport from "src/pages/Dashboard/Pages/Reports/RollingReport.vue";
import AgentRollingReport from "src/pages/Dashboard/Pages/Reports/AgentRollingReport.vue";
import AgentRollingReportDrillDown from "src/pages/Dashboard/Pages/Reports/AgentRollingReportDrillDown.vue";
import DealerSignupLookup from "src/pages/Dashboard/Pages/DealerSignups/DealerSignupLookup.vue";
import Claims from "../pages/Dashboard/Pages/Claims.vue";

// Users
import UserAdmin from 'src/pages/Dashboard/Pages/Users/UserAdmin.vue'
import AuthGroupsScopesAdmin from "../pages/Dashboard/Pages/Users/AuthGroupsScopesAdmin.vue";
import UserAuthgroupsAdmin from "../pages/Dashboard/Pages/Users/UserAuthgroupsAdmin.vue";

// Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Lock from 'src/pages/Dashboard/Pages/Lock.vue'

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
import Panels from 'src/pages/Dashboard/Components/Panels.vue'
const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
import Icons from 'src/pages/Dashboard/Components/Icons.vue'
import Typography from 'src/pages/Dashboard/Components/Typography.vue'

// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')

// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let dealerWizard = {
  path: '/dealerwizard',
  name: 'Dealer Wizard',
  component: DealerAndAgentWizard
};

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

let claimsPage = {
  path: '/claims',
  name: 'Claims',
  component: Claims
}

const usersMenu = {
  path: '/users',
  name: 'usermain',
  component: DashboardLayout,
  redirect: '/users/users',
  children: [
    {
      path: 'users',
      name: 'User Admin',
      component: UserAdmin
    },
    {
      path: 'scopes',
      name: 'User Scopes',
      component: AuthGroupsScopesAdmin
    },
    {
      path: 'groups',
      name: 'User Groups',
      component: UserAuthgroupsAdmin
    },
  ]
}

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/admin/overview'
  },
  usersMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  registerPage,
  claimsPage,
  dealerWizard,
  lockPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'dealersignups',
        name: 'Dealer Signups',
        component: DealerSignupLookup
      },
      {
        path: 'stats',
        name: 'Stats',
        component: Stats
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'generate',
        name: 'PCMI Report Conversion',
        component: PCMIReportsTranslation
      },
      {
        path: 'dna',
        name: 'DNA Report',
        component: DNAReport
      },
      {
        path: 'quest',
        name: 'Quest Report',
        component: QuestReport
      },
      {
        path: 'nova',
        name: 'NOVA Report',
        component: NOVAReport
      },
      {
        path: 'rollingreport',
        name: 'Rolling Report',
        component: RollingReport
      },
      {
        path: 'agentrollingreportdd/:id/:month',
        name: 'Agent Rolling Report Drill Down',
        component: AgentRollingReportDrillDown
      },
      {
        path: 'agentrollingreport',
        name: 'Agent Rolling Report',
        component: AgentRollingReport
      },
      {
        path: 'codes',
        name: 'Coverage Codes',
        component: CoverageCodes
      },
    ]
  },
  {path: '*', component: NotFound}
]

export const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});
router.beforeEach((to, from, next) => {
  const publicPages = ['/claims','/login','/register','/verification','/forgot','/dealerwizard'];
  const authRequired = !publicPages.includes(to.path);
  const store = useLoginStore();
  const loggedIn = store.loggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
