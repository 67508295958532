<template>
  <ValidationObserver v-slot="{ }">
    <div>
      <div id="floating-banner" v-if="error_banner">
      </div>
      <div class="row">
        <div class="col-sm-2 background_blue">
        </div>
        <div class="col-sm-8 background_light_blue">
          <card>

            <!-- MAIN CONTENT AREA -->
            <div class="row">
              <div class="col-sm-12 text-center">
                <img :src="bannerImg" alt="Ascent Claims Banner">
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 text-center">
                <h2>Submit your Claim Online</h2>
                <h3>Estimate Form</h3>
                <h4>All fields with * are required</h4>
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <h2>Contact Details</h2>
                <br>
                <hr>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="repairShopName" rules="required" v-slot="{ errors }">
                  <label for="repairShopName">Shop Name: *</label>
                  <fg-input v-model="form.repairShopName" name="repairShopName"
                            type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="phone" rules="required" v-slot="{ errors }">
                  <label for="phone">Phone: *</label>
                  <input id="phone" placeholder="(000)000-0000" v-model="formattedPhone"
                         name="phone"
                         type="tel" class="form-control">
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="email" rules="required" v-slot="{ errors }">
                  <label for="email">Email: *</label>
                  <fg-input id="email" placeholder="example@example.com" v-model="form.email"
                            name="email" type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="paymentEmail" rules="required" v-slot="{ errors }">
                  <label for="paymentEmail">Payment Email: *</label>
                  <fg-input id="paymentEmail" placeholder="example@example.com"
                            v-model="form.paymentEmail" name="paymentEmail" type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="repairShopAddress" rules="required" v-slot="{ errors }">
                  <label for="repairShopAddress">Repair Shop Address: *</label>
                  <fg-input id="repairShopAddress" placeholder="street address"
                            v-model="form.repairShopAddress" name="repairShopAddress"
                            type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="repairShopCity" rules="required" v-slot="{ errors }">
                  <label for="repairShopCity">City: *</label>
                  <fg-input id="repairShopCity" placeholder="City" v-model="form.repairShopCity"
                            name="repairShopCity" type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-sm-4">
                <ValidationProvider name="repairShopState" rules="required" v-slot="{ errors }">
                  <label for="repairShopState">State: *</label>
                  <select class="form-control" v-model="form.repairShopState"
                          name="repairShopState"
                          id="repairShopState" data-component="state" required="">
                    <option selected="" value="">Please Select</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="District of Columbia">District of Columbia</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="repairShopZip" rules="required" v-slot="{ errors }">
                  <label for="repairShopZip">Zip: *</label>
                  <fg-input id="repairShopZip" placeholder="Zip Code"
                            v-model="form.repairShopZip" name="repairShopZip"
                            type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="vin" rules="required" v-slot="{ errors }">
                  <label for="vin">VIN Number: *</label>
                  <fg-input id="vin" v-model="form.vin" name="vin" type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Customer Name: *</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <ValidationProvider name="customerFirst" rules="required" v-slot="{ errors }">
                  <fg-input id="customerFirst" placeholder="First Name" v-model="form.customerFirst"
                            name="customerFirst" type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-sm-6">
                <ValidationProvider name="customerLast" rules="required" v-slot="{ errors }">
                  <fg-input id="customerLast" placeholder="Last Name" v-model="form.customerLast"
                            name="customerLast" type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="asc_certified" rules="required" v-slot="{ errors }">
                  <label for="asc_certified">ASE or Factory Certified Technicians *</label>
                  <div v-for="option in ace_certified_options" :key="option.value">
                    <input
                      type="radio"
                      :id="option.value"
                      :value="option.value"
                      v-model="form.ace_certified"
                    >
                    <label :for="option.value">{{ option.label }}</label>
                  </div>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="mileage" rules="required" v-slot="{ errors }">
                  <label for="mileage">Mileage: *</label>
                  <fg-input id="mileage" v-model="form.mileage" name="mileage" min=0
                            type="number"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="laborRatePerHour" rules="required" v-slot="{ errors }">
                  <label for="laborRatePerHour">Labor Rate Per Hour: *</label>
                  <fg-input id="labelRatePerHour" v-model="form.laborRatePerHour"
                            name="laborRatePerHour"
                            type="text" placeholder="EX. $100"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="repairOrderNumber" rules="required" v-slot="{ errors }">
                  <label for="repairOrderNumber">Repair Order Number: *</label>
                  <fg-input id="repairOrderNumber" v-model="form.repairOrderNumber"
                            name="repairOrderNumber"
                            type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="taxRate" rules="required" v-slot="{ errors }">
                  <label for="taxRate">Tax Rate %: *</label>
                  <fg-input id="taxRate" v-model="form.taxRate" name="taxRate"
                            type="text"></fg-input>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="tax" rules="required" v-slot="{ errors }">
                  <label for="tax">Tax *</label>
                  <div v-for="option in tax_options" :key="option.value">
                    <input
                      type="radio"
                      :id="option.value"
                      :value="option.value"
                      v-model="form.tax"
                    >
                    <label :for="option.value">{{ option.label }}</label>
                  </div>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="towedOrDriven" rules="required" v-slot="{ errors }">
                  <label for="towedOrDriven">Towed in or Driven in *</label>
                  <div v-for="option in towed_or_driven_in_options" :key="option.value">
                    <input
                      type="radio"
                      :id="option.value"
                      :value="option.value"
                      v-model="form.towed_or_driven"
                    >
                    <label :for="option.value">{{ option.label }}</label>
                  </div>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="warranty_on_parts" rules="required" v-slot="{ errors }">
                  <label for="warranty_on_parts">Warranty on Parts/Labor *</label>
                  <textarea class="form-control" cols="10" rows="2"
                            v-model="form.warranty_on_parts_or_labor"></textarea>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                  We require at least 12/12
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="customer_complaint" rules="required" v-slot="{ errors }">
                  <label for="customer_complaint">Customer Complaint *</label>
                  <textarea class="form-control" cols="10" rows="5"
                            v-model="form.customer_complaint"></textarea>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                  Multiple complaints can be added here
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="cause_of_failure" rules="required" v-slot="{ errors }">
                  <label for="cause_of_failure">cause of failure *</label>
                  <textarea class="form-control" cols="10" rows="5"
                            v-model="form.cause_of_failure"></textarea>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                  multiple complaints can be added here
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="correction" rules="required" v-slot="{ errors }">
                  <label for="correction">Correction *</label>
                  <textarea class="form-control" cols="10" rows="5"
                            v-model="form.correction"></textarea>
                  <span class="error_span" v-if="errors.length">{{ errors[0] }}</span>
                  multiple corrections can be added here
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <h2>Job Description</h2>
                <h3>You can save and add multiple lines for labor and parts. Select save and add.
                  Please do not leave out any parts or labor required.</h3>
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label for="parts">Parts *</label>
              </div>
            </div>
            <div class="row" v-for="field in fields" :key="field.name">
              <div class="col-sm-8">
                <ValidationProvider :name="field.name" rules="required" v-slot="{ errors }">
                  <label :for="field.name">{{ field.label }}</label>
                  <fg-input :type="field.type" v-model="form.part[field.name]"></fg-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 text-center">
                <button @click="saveAndAdd" class="btn btn-primary">Save and Add</button>
              </div>
              <div class="row text-center">
                <div class="col-sm-12 text-center">
                  <!-- Table to display the parts -->
                  <el-table :data="parts">
                    <el-table-column width="200" prop="name"
                                     label="Part Name"></el-table-column>
                    <el-table-column width="150" prop="number"
                                     label="Part Number"></el-table-column>
                    <el-table-column width="100" prop="quantity" label="Quantity"></el-table-column>
                    <el-table-column width="100" prop="price" label="Price"></el-table-column>
                    <el-table-column width="100" label="Actions">
                      <template slot-scope="scope">
                        <el-button size="mini" @click="removePart(scope.$index)">-</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label for="parts">Labor *</label>
              </div>
            </div>
            <div class="row" v-for="field in labor_fields" :key="field.name">
              <div class="col-sm-8">
                <ValidationProvider :name="field.name" rules="required" v-slot="{ errors }">
                  <label :for="field.name">{{ field.label }}</label>
                  <fg-input :type="field.type" v-model="form.labor[field.name]"></fg-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 text-center">
                <button @click="saveAndAddLabor" class="btn btn-primary">Save and Add</button>
              </div>
              <div class="row text-center">
                <div class="col-sm-12 text-center">
                  <!-- Table to display the parts -->
                  <el-table :data="labor">
                    <el-table-column width="200" prop="job" label="Job"></el-table-column>
                    <el-table-column width="300" prop="description"
                                     label="Description"></el-table-column>
                    <el-table-column width="100" prop="hours" label="Hours"></el-table-column>
                    <el-table-column label="Actions">
                      <template slot-scope="scope">
                        <el-button size="mini" @click="removeLabor(scope.$index)">-</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <ValidationProvider name="start_date" rules="required" v-slot="{ errors }">
                  <label>Date of Estimated Completion *</label>
                  <datepicker
                    class="editable editable-click"
                    :bootstrap-styling="true"
                    :format="customFormatter"
                    placeholder="Start Date"
                    v-model="form.start_date">
                  </datepicker>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <label>Comments</label>
                <textarea cols="10" rows="10" class="form-control"
                          v-model="form.comments"></textarea>
              </div>
            </div>

            <div  class="photo-upload">
              <input 
                type="file" 
                id="photo1" @change="uploadPhoto($event)"
                accept="*"
                capture="environment"
                style="display: none"
              />
              <button @click="triggerInput()" class="btn btn-info">Add Photo or Document</button>
            </div>

            <div class="drop-area"
                 @dragover.prevent="dragOver"
                 @dragenter.prevent="dragEnter"
                 @dragleave.prevent="dragLeave"
                 @drop.prevent="handleDrop">
              Drag and drop photos and documents here to upload
              <div v-if="uploading && currentUploadType === 'photo'">
                Uploading files... Please wait.
              </div>

              <div v-if="form.photos.length" class="grid-container">
                <div v-for="(photo, index) in form.photos" :key="index" class="image-container">
                  <img :src="photo.dataUrl" :alt="`Image ${index + 1}`" class="image-item" />
                  <button @click="deleteFile(index, 'photos')" class="delete-button"> X </button>
                </div>
              </div>

              <div v-if="form.files.length" class="grid-container">
                <div v-for="(document, index) in form.files" :key="index" class="document-container">
                  <img src="/static/img/document-icon-vector-5.jpg" class="image-item" />
                  <button @click="deleteFile(index, 'files')" class="delete-button"> X </button>
                  <p class="document-name">{{ document.name }}</p>
                </div>
              </div>

            </div>

            <div v-if="uploadSuccess">
              Upload successful!
            </div>
            <div v-if="uploadError">
              Failed to upload: {{ uploadErrorMessage }}
            </div>
            <div class="row">
              <hr>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label>Service Advisor Name *</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <fg-input type="text" v-model="form.service_advisor_first_name"
                          placeholder="First Name"></fg-input>
              </div>
              <div class="col-sm-6">
                <fg-input type="text" v-model="form.service_advisor_last_name"
                          placeholder="Last Name"></fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="start_date" rules="required" v-slot="{ errors }">
                  <label>Advisor Direct Phone Number *</label>
                  <fg-input type="text" v-model="formattedAdvisorPhone"
                            placeholder="(000)000-0000"></fg-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="start_date" rules="required" v-slot="{ errors }">
                  <label>Advisor Email *</label>
                  <fg-input type="text" v-model="form.advisor_email"
                            placeholder="something@something.com"></fg-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ValidationProvider name="start_date" rules="required" v-slot="{ errors }">
                  <label>Vehicle Received Date *</label>
                  <datepicker
                    class="editable editable-click"
                    :bootstrap-styling="true"
                    :format="customFormatter"
                    placeholder="Vehicle Received Date"
                    v-model="form.vehicle_received_date">
                  </datepicker>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                If you encounter any difficulties while submitting this form, kindly forward the
                estimate to claims@ascentadmin.com. Ensure that you include all the particulars
                outlined in the form at the time of your submission. It's important to note that
                this form is designated for repair facilities, and it is imperative that we receive
                all the requisite information to facilitate the processing of your claim. Omitting
                any information on this form will inevitably result in a delay in processing your
                claim.

                We kindly request you to monitor the email address associated with your claim
                submission, as we will promptly respond once we have processed your claim. If any
                additional information is required, we will promptly inform you. You hereby affirm
                and warrant that all information provided in this document is true and correct to
                the best of your knowledge, belief, and understanding. Failure to submit accurate
                data may result in legal consequences, including but not limited to penalties,
                fines, or other legal actions.

                Your cooperation is greatly appreciated. Thank you.
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <el-button type="primary" @click="saveClaim">Save Claim</el-button>
                <el-button type="primary" @click="submitClaim">Submit Claim</el-button>
              </div>
            </div>
          </card>
        </div>
        <div class="col-sm-2 background_blue">
        </div>
      </div>
      <el-dialog
        center
        title="Success"
        :visible.sync="modals.success">
        <div class="text-center">
          <span>Success: {{ success_txt }}</span>
          <br>
          <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
</span>
        </div>
      </el-dialog>
      <el-dialog
        center
        title="Error"
        :visible.sync="modals.error">
        <div class="text-center">
          <span>Error: {{ error_txt }}</span>
          <br>
          <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
        </div>
      </el-dialog>
    </div>
  </ValidationObserver>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import { Dialog, Table, TableColumn, Button } from 'element-ui';
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import ClaimAPIService from "../../../servicehandlers/ClaimAPIService";
import PhotosAPIHandler from "../../../servicehandlers/PhotosAPIHandler";


const photosAPIHandler = new PhotosAPIHandler();
const claimAPIService = new ClaimAPIService();

export default {
  computed: {
    formattedPhone: {
      get() {
        // When getting the value, format the rawPhone state
        return this.formatPhoneNumber(this.rawPhone);
      },
      set(value) {
        // When setting the value, store the raw input in rawPhone
        this.rawPhone = value.replace(/\D/g, ''); // Remove all non-digit characters
      }
    },
    formattedAdvisorPhone: {
      get() {
        // When getting the value, format the rawPhone state
        return this.formatPhoneNumber(this.form.advisor_direct_phone);
      },
      set(value) {
        // When setting the value, store the raw input in rawPhone
        this.form.advisor_direct_phone = value.replace(/\D/g, ''); // Remove all non-digit characters
      }
    }
  },
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    Datepicker
  },
  data() {
    return {
      rawPhone: '',
      previewImage: [],
      ace_certified_options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: 'Other', value: 'other' }
      ],
      tax_options: [
        { label: 'Parts', value: 'Parts' },
        { label: 'Labor and Parts', value: 'Labor and Parts' }
      ],
      towed_or_driven_in_options: [
        { label: 'Towed', value: 'Towed' },
        { label: 'Driven', value: 'Driven' }
      ],
      bannerImg: require('@/assets/images/PCMI_banner.png'),
      error_banner: false,
      fields: [
        { name: 'name', label: 'Part Name', type: 'text' },
        { name: 'number', label: 'Part Number', type: 'text' },
        { name: 'quantity', label: 'Quantity', type: 'number' },
        { name: 'price', label: 'Price', type: 'number' }
      ],
      labor_fields: [
        { name: 'job', label: 'Job', type: 'text' },
        { name: 'description', label: 'Description', type: 'text' },
        { name: 'hours', label: 'Hours', type: 'number' },
      ],
      parts: [],
      labor: [],
      start_date: null,
      uploading: false,
      uploadSuccess: false,
      uploadError: false,
      uploadErrorMessage: '',
      currentUploadType: '',
      modals: {
        error: false,
        success: false,
      },
      error_txt: null,
      success_txt: null,
      form: {
        repairShopName: '',
        phone: '',
        vin: '',
        email: '',
        paymentEmail: '',
        repairShopAddress: '',
        repairShopCity: '',
        repairShopState: '',
        repairShopZip: '',
        customerFirst: '',
        customerLast: '',
        ace_certified: 'yes',
        mileage: '',
        laborRatePerHour: '',
        repairOrderNumber: '',
        taxRate: '',
        tax: 'Parts',
        comments: '',
        towed_or_driven: 'Driven',
        warranty_on_parts_or_labor: '',
        customer_complaint: '',
        cause_of_failure: '',
        correction: '',
        part: {
          'name': '',
          'number': '',
          'quantity': '',
          'part_price': '',
        },
        labor: {
          'job': '',
          'description': '',
          'hours': '',
        },
        date_of_completion: '',
        photos: [],
        files: [],
        service_advisor_first_name: '',
        service_advisor_last_name: '',
        advisor_direct_phone: '',
        advisor_email: '',
        vehicle_received_date: '',
      }
    };
  },
  methods: {
    deleteFile(index, type) {
      this.form[type].splice(index, 1);
    },
    formatPhoneNumber(value) {
      const numbers = value.replace(/\D/g, '');
      const char = { 0: '(', 3: ') ', 6: '-' };
      let phone = '';
      for (let i = 0; i < numbers.length; i++) {
        phone += (char[i] || '') + numbers[i];
      }
      return phone;
    },
    dragOver() {
      // Optional: Provide visual feedback during drag-over.
    },
    dragEnter() {
      // Optional: Provide visual feedback on drag enter.
    },
    dragLeave() {
      // Optional: Provide visual feedback on drag leave.
    },
    handleDrop(event) {

     event.preventDefault();

     const files = event.dataTransfer.files;

     if (files.length > 0) {
        this.uploadFiles(files);
     }
    },
    uploadFiles(files) {
      this.uploading = true;
      this.uploadSuccess = false;
      this.uploadError = false;

      const formData = new FormData();

      Array.from(files).forEach(file => {
        formData.append('files', file);

        const reader = new FileReader();

        reader.onload = (e) => {
          if (file.type.startsWith('image/')) {
            this.form.photos.push({ dataUrl: e.target.result, name: file.name });
          } else {
            this.form.files.push({ name: file.name, type: file.type });
          }
        };
        reader.readAsDataURL(file);

      });

      ///photosAPIHandler.uploadPhotos('test', formData, this.$router);

    },
    triggerInput() {
      document.getElementById('photo1').click();
    },
    uploadPhoto(event ) {
      const files = event.target.files;
      this.uploadFiles(files);
    },
    saveClaim() {
    },
    saveAndAdd() {
      this.parts.push({ ...this.form.part });
      this.form.part = { name: '', number: '', quantity: '', price: '' }; // Reset form
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    removePart(index) {
      this.parts.splice(index, 1);
    },
    saveAndAddLabor() {
      this.labor.push({ ...this.form.labor });
      this.form.labor = { job: '', description: '', hours: '' }; // Reset form
    },
    removeLabor(index) {
      this.labor.splice(index, 1);
    },
    submitClaim() {
      this.error_banner = false;
      // upload the claim to the PCMI server
      const claim_to_create = {
        claim: {},
        parts: [],
        labor: [],
      };
      claim_to_create.claim = {
        phone: this.formattedPhone,
        towed_or_driven: this.form.towed_or_driven,
        start_date: this.form.start_date,
        repair_shop_name: this.form.repairShopName,
        vin: this.form.vin,
        email: this.form.email,
        paymentEmail: this.form.paymentEmail,
        repair_shop_address: this.form.repairShopAddress,
        repair_shop_city: this.form.repairShopCity,
        repair_shop_state: this.form.repairShopState,
        repair_shop_zip: this.form.repairShopZip,
        customer_first: this.form.customerFirst,
        customer_last: this.form.customerLast,
        ace_certified: this.form.ace_certified,
        mileage: this.form.mileage,
        labor_rate_per_hour: this.form.laborRatePerHour,
        repair_order_number: this.form.repairOrderNumber,
        tax_rate: this.form.taxRate,
        tax: this.form.tax,
        comments: this.form.comments,
        service_advisor_first_name: this.form.service_advisor_first_name,
        service_advisor_email: this.form.advisor_email,
        service_advisor_last_name: this.form.service_advisor_last_name,
        service_advisor_phone_number: this.formattedAdvisorPhone,
        vehicle_received_date: this.form.vehicle_received_date
      };
      claim_to_create.parts = this.parts;
      claim_to_create.labor = this.labor;
      claim_to_create.photos = this.form.photos;
      claim_to_create.files = this.form.files;

      const claim = claimAPIService.create(claim_to_create, this.$route)
      .then((response) => {
        this.handleSuccess('Claim successfully created!');
      })
      .catch((error) => {
        this.handleError('Claim creation failed!');
      });
    },
    handleSuccess(text) {
      this.success_txt = text;
      this.openModal('success');
      this.spinning = false;
      setTimeout(() => {
        this.modals['success'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    handleError(error) {
      this.error_txt = error;
      this.openModal('error');
      this.spinning = false;
      setTimeout(() => {
        this.modals['error'] = false;
      }, 1500); // Hide after 1.5 secs
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
  },
  created() {
    extend('required', {
      ...required,
      message: 'This field is required'
    });
  },

}

</script>

<style>
.text-danger {
  color: #ff0000;
}

.custom-radio-group label {
  margin-right: 20px;
}

.custom-radio-group input[type="radio"] {
  opacity: 0; /* Hide default radio buttons */
}

.custom-radio-group input[type="radio"]:checked + label {
  background-color: lightblue;
  border-color: blue;
}

.error_span {
  color: red;
}

.btn-info {
  margin: 10px;
  background-color: #17a2b8;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.background_blue {
  background-color: #2c6cf6;
}

.background_light_blue {
  background-color: #c6d5f6;
}

#floating-banner {
  position: fixed; /* Stays in place on scroll  */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0; /* Example background color */
  padding: 15px;
  text-align: center;
  z-index: 1000; /* Ensures banner stays above other content */
}

.drop-area {
  border: 2px dashed #444;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  color: black;
  margin: 20px;
  background:#EEE;
  min-height:200px;
}

.drop-area.drag-over {
  border-color: #000;
  color: #000;
}

.editable-click {
  cursor: pointer;
}

.error_span {
  color: red;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.image-container, .document-container {
  position: relative;
  width: 200px;
}
.document-name {
  bottom: 5px;
  left: 0;
  right: 0;
  margin: auto;
  background: #FFF;
  color: black;
  border:1px solid black;
  padding: 3px;
  border-radius: 5px;
}

.image-item {
  width: 100%;
  display: block;
}
.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #FFF;
  border: 1px solid black;
  border-radius:5px;
  color: black;
  cursor: pointer;
}

font-awesome-icon {
  width: 20px;
}

</style>
