<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row col-sm-8" style="padding-top: 1em!important; padding-bottom:1em;">
        <div class="col-sm-4 epad">
          <select style="height: 2.0em;font-size:1.2em;" name="Code" id="rtype" v-model="selected_type">
            <option v-for="rtype of report_types" v-bind:key="rtype.id" :value="rtype.id">{{rtype.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <br/>
        <div class="col-sm-8 epad">
          <button class="btn btn-primary" @click="getRollingReport">Get Agent Rolling Report</button>
        </div>
      </div>
    </card>
    <card v-if="is_ran">
      <div class="row">
        <div class="col-sm-6 text-left">
          <download-excel
            class="btn btn-primary"
            :data="reportData"
            :fields="json_fields"
            worksheet="Agent Rolling Report"
            name="Agent Rolling Report.xls">

            Download Excel
          </download-excel>
        </div>
      </div>
      <div class="row table-header custom-row sortable" @click="sortBy('Agent Name')">
        <div class="col-sm-3">
          Agent Name
          <span v-if="sortKey === 'Agent Name'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>

        </div>
        <!-- Dynamically generate the month headers -->
        <div class="col-sm-1" v-for="(month, index) in months" :key="'header-' + index">
          {{ monthnames_abbr[ parseInt(month.split('-')[1]) - 1 ] }}
        </div>

      </div>
      <div
        class="row custom-row"
        v-for="(reportItem, index) in sortedReports"
        v-bind:key="reportItem.id"
        :class="{'table-light': index %2}"
      >
        <div class="col-sm-3">{{ reportItem['Agent Name'] }}</div>
        <!-- Dynamically render the columns for the last 15 months -->
        <div
          class="col-sm-1"
          v-for="(month, monthIndex) in months"
          :key="monthIndex"
        >
          <v-btn class="link-style" color="primary" @click="agentDrillDown(reportItem['Agent #'], month)">
            {{ reportItem[month] }}
          </v-btn>

        </div>

      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{success_txt}}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import ReportsAPIHandler from "src/servicehandlers/ReportsAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
import { Dialog } from 'element-ui';

export default {
  components: {
    [Dialog.name]: Dialog,
  },
  name: "AgentRollingReport",
  data() {
    return {
      spinning: false,
      reportData: [],
      months: [],
      is_ran: false,
      totalCount: 0,
      success_txt: null,
      error_txt: null,
      selected_type: 2,
      monthType: 'Activated',
      monthnames: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      monthnames_abbr: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      report_types: [{id:1, name: 'Pending Contracts'}, {id:2, name: 'Activated Contracts'}],
      json_fields: {
        'Agent Name': 'Agent Name'
      },
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
      sortKey: 'Agent Name',  // Default sort by AgentName
      sortOrder: 'asc',      // Default sort order
    }
  },
  created() {
    //console.log(this.$route.params.id); // Check if the ID is coming through correctly
    this.generateLast15Months();
    this.getRollingReport();
  },
  computed: {
    sortedReports() {
      return this.reportData
        .slice()
        .sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1;
        if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
        if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
        return 0;
      });
    }
  },
  methods: {
    monthName(numeric) {
      return this.monthnames[numeric - 1];
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle sort order
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc'; // Default to ascending
      }
    },
    agentDrillDown(id, month){
      this.$router.push({
        name: 'Agent Rolling Report Drill Down',
        params: { id, month},
        query: { rtype: this.selected_type }
      });
    },
    generateLast15Months() {
      const currentDate = new Date();
      const months = [];

      // Generate the last 15 months (from the current month backwards)
      for (let i = 14; i >= 0; i--) {
        const date = new Date(currentDate);
        date.setMonth(currentDate.getMonth() - i);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        months.push(`${year}-${month}`);
        this.json_fields[`${year}-${month}`]  = `${year}-${month}`;
      }
      this.months = months;
    },
    getRollingReport() {

      const types = ['', 'Inserted', 'Activated'];
      this.monthType = types[this.selected_type];

      this.is_ran = false;
      this.spinning = true;
      const rtype = this.selected_type;
      const parameters = { params:{ rtype }};

      reportsAPIHandler.getAgentRollingReport( parameters, this.$route)
      .then((resp) => {

        this.reportData = [];
        this.totalCount = 0;
        let count = 1;

        for (const item of resp) {
          item.id = count;
          this.totalCount += parseInt(item['Count']);
          this.reportData.push(item);
          count++;
        }

        this.is_ran = true;
        this.spinning = false;

      })
      .catch((error) => {

        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');

      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>
  .epad {padding-top: .5em; padding-bottom: .5em;}
  .fixcursor {cursor:pointer!important;}
  .table-light {background-color: #EEE;}
  .table-header {background-color: #AAA; font-weight:bold;}

  /* Custom row to reduce padding */
  .custom-row {
    margin-left: 0;
    margin-right: 0;
  }

  .custom-row > [class*='col-'] {
    padding-left: 1px;  /* Adjust padding to reduce spacing */
    padding-right: 1px; /* Adjust padding to reduce spacing */
  }

  .custom-row > [class~='col-sm-1'] {
    flex: 0 0 5%;
    max-width: 5%;
  }

  .link-style {
    color: #1976d2; /* Default link color */
    text-transform: none; /* Prevents uppercase transform from Vuetify */
    cursor: pointer;
    padding: 0; /* Removes padding to make it like a link */
    text-decoration: none; /* Adds underline to mimic a link */
  }

  .sortable {
    cursor: pointer;
    user-select: none;
  }

</style>
