<template>
  <div>

    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card v-if="is_ran">
      <div class="row">
        <div class="col-sm-3">{{ reportData[0]['AgentName'] }}</div>
        <div class="col-sm-3">{{ reportData[0]['AgentNumber'] }}</div>
        <div class="col-sm-3">{{ this.month }}</div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-left">
          <download-excel
            class="btn btn-primary"
            :data="reportData"
            :fields="json_fields"
            worksheet="Agent Report"
            name="Agent Report.xls">

            Download Excel
          </download-excel>
        </div>
      </div>
      <div class="row table-header custom-row">

        <div class="col-sm-2 sortable"  @click="sortBy('Inserted')">
          Inserted <span v-if="sortKey === 'Inserted'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
        </div>

        <div class="col-sm-2 sortable"  @click="sortBy('ActivationDate')">
          Activated <span v-if="sortKey === 'ActivationDate'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
        </div>

        <div class="col-sm-2 sortable"  @click="sortBy('ContractNumber')">
          Contract <span v-if="sortKey === 'ContractNumber'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
        </div>

        <div class="col-sm-3 sortable"  @click="sortBy('DealerName')">
          Dealer <span v-if="sortKey === 'DealerName'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
        </div>

        <div class="col-sm-3 sortable"  @click="sortBy('VehicleYear')">
          Vehicle <span v-if="sortKey === 'VehicleYear'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
        </div>

        <div class="col-sm-2 sortable"  @click="sortBy('ProductType')">
          Product <span v-if="sortKey === 'ProductType'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
        </div>


      </div>
      <div
        class="row custom-row"
        v-for="(reportItem, index) in sortedReports"
        v-bind:key="reportItem.id"
        :class="{'table-light': index %2}"
      >
        <div class="col-sm-2">{{ reportItem['Inserted'] }}</div>
        <div class="col-sm-2">{{ reportItem['ActivationDate'] }}</div>
        <div class="col-sm-2">{{ reportItem['ContractNumber'] }}</div>
        <div class="col-sm-3">{{ reportItem['DealerName'] }}</div>
        <div class="col-sm-3">{{ reportItem['VehicleYear'] }} {{ reportItem['VehicleMake'] }} {{ reportItem['VehicleModel'] }}</div>
        <div class="col-sm-2">{{ reportItem['ProductType'] }}</div>

      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{success_txt}}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import ReportsAPIHandler from "src/servicehandlers/ReportsAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
import { Dialog } from 'element-ui';

export default {
  components: {
    [Dialog.name]: Dialog,
  },
  name: 'AgentRollingReportDrillDown',
  data() {
    return {
      spinning: false,
      sortKey: 'Inserted',  // Default sort by AgentName
      sortOrder: 'asc',      // Default sort order
      reportData: [],
      months: [],
      id: 0,
      month: 'month',
      rtype: 'rtype',
      is_ran: false,
      totalCount: 0,
      success_txt: null,
      error_txt: null,
      selected_type: 2,
      monthType: 'Activated',
      monthnames: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      monthnames_abbr: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      report_types: [{id:1, name: 'Pending Contracts'}, {id:2, name: 'Activated Contracts'}],
      json_fields: {
        'Agent Name': 'AgentName',
        'Agent Number': 'AgentNumber',
        'Date Inserted': 'Inserted',
        'Date Activated': 'ActivationDate',
        'Contract Number': 'ContractNumber',
        'Dealer Name': 'DealerName',
        'Dealer Number': 'DealerNumber',
        'Vehicle Year': 'VehicleYear',
        'Vehicle Make': 'VehicleMake',
        'Vehicle Model': 'VehicleModel',
        'Product Type': 'ProductType',
        'Components': 'ComponentDescriptions'

  },
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
    }
  },
  created() {
    //console.log(this.$route.params.id); // Check if the ID is coming through correctly
  },
  mounted() {
    // Access route parameters using 'this.$route.params'
    const id = this.$route.params.id;
    const month = this.$route.params.month;
    const rtype = this.$route.query.rtype;

    this.getAgentReport({id, month, rtype});
  },
  computed: {
    sortedReports() {
      return this.reportData
        .slice()
        .sort((a, b) => {
          let modifier = this.sortOrder === 'asc' ? 1 : -1;
          if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
          if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
          return 0;
        });
    }
  },
  methods: {
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle sort order
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc'; // Default to ascending
      }
    },
    getAgentReport(params) {


      this.id = params.id;
      this.month = params.month;
      this.rtype = params.rtype;

      const types = ['', 'Inserted', 'Activated'];
      this.monthType = types[this.selected_type];

      this.is_ran = false;
      this.spinning = true;

      const id = this.id;
      const month = this.month;
      const rtype = this.rtype;

      const parameters = { params:{ id, month, rtype }};

      reportsAPIHandler.getAgentReport( parameters, this.$route)
      .then((resp) => {

        this.reportData = [];
        this.totalCount = 0;
        let count = 1;

        for (const item of resp) {
          item.id = count;
          this.totalCount += parseInt(item['Count']);
          this.reportData.push(item);
          count++;
        }

        this.is_ran = true;
        this.spinning = false;

      })
      .catch((error) => {

        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');

      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>
  .epad {padding-top: .5em; padding-bottom: .5em;}
  .fixcursor {cursor:pointer!important;}
  .table-light {background-color: #EEE;}
  .table-header {background-color: #AAA; font-weight:bold;}

  /* Custom row to reduce padding */
  .custom-row {
    margin-left: 0;
    margin-right: 0;
  }

  .custom-row > [class*='col-'] {
    padding-left: 5px;  /* Adjust padding to reduce spacing */
    padding-right: 5px; /* Adjust padding to reduce spacing */
  }

  .custom-row > [class~='col-sm-1'] {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .custom-row > [class~='col-sm-2'] {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .custom-row > [class~='col-sm-3'] {
    flex: 0 0 16%;
    max-width: 16%;
  }


  .link-style {
    color: #1976d2; /* Default link color */
    text-transform: none; /* Prevents uppercase transform from Vuetify */
    cursor: pointer;
    padding: 0; /* Removes padding to make it like a link */
    text-decoration: none; /* Adds underline to mimic a link */
  }

  .sortable {
    cursor: pointer;
    user-select: none;
  }

</style>
